import Aos from "aos";
import courseImg from "../../assets/images/course.png"
import "./course.scss"
import { useEffect } from "react";

const Course = () => {
         const handleClick = () => {
           window.location.href = "/contact-us";
           console.log("Clicked");
         };

  useEffect(()=> {
    Aos.init();
  },[])

  return (
    <div className="container">
      <div className="course">
        <div className="content">
          <div className="section1" data-aos="fade-down">
            <h2>
              Are you ready to embark towards
              <br />
              freelancing career?
            </h2>
            <div className="paragraph">
              <p>
                {" "}
                If so, we have prepared for you interactive and immersive course
                on freelancing to take your career into new heights. We are
                partnering with Ms. <span>Finesa Shala</span>, professional
                freelancer operating with great success in <span>EU</span> and{" "}
                <span>US </span>
                markets for some years now. The training will provide insights
                into effective self-promotion empowering you to secure rewarding
                opportunities in the competitive freelancing landscape.
              </p>
            </div>
            <button onClick={handleClick}>Join now</button>
          </div>
          <div className="section2" data-aos="fade-left" >
            <div className="content2">
              <div className="custom-satsf cnt">
                <h4 className="nr">35</h4>
                <h6>hours</h6>
              </div>
              <div className="barrier"></div>
              <div className="serv-prov cnt">
                <h4 className="nr">3</h4>
                <h6 className="h6sec2">Times per week</h6>
              </div>
              <div className="barrier"></div>
              <div className="creative-emp cnt">
                <h4 className="nr">4</h4>
                <h6 className="h6sec2">Hours per day</h6>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="row sec3items">
              <div className="col-sm-6">
                <div className="col1-text-container col1"data-aos="fade-right" >
                  <h1 className="trainingtxt">
                    Training will
                    <br /> be held ONLINE!
                  </h1>
                  <p className="sec3paragraph">
                    For those successfully completing the training, Finesa will
                    support you getting first freelancing engagements and
                    contracts.
                  </p>
                </div>
              </div>
              <div className="col-sm-6" data-aos="fade-left">
                <img src={courseImg} className="pic" alt="Course image" />
              </div>
            </div>
          </div>
          <div className="section4">
            <h1 className="s4-title" data-aos="fade-up">
              The promotional
              <br />
              Price
            </h1>
            <div className="option">
              <div className="option1" data-aos="fade-right">
                <h1>Early price</h1>
                <div className="bullet-points">
                  <p className="feature">
                    Comprehensive Introduction to Freelancing
                  </p>
                  <p className="feature">Step-by-Step Guidance</p>
                  <p className="feature">Practical Tips and Strategies</p>
                  <p className="feature">Access to Resource Materials</p>
                  <p className="feature">Live Q&A Sessions</p>
                  <p className="feature">Limited-Time Offer</p>
                </div>
                <div className="price-section">
                  <p className="price">250€</p>
                  <p className="txt">one time payment</p>
                </div>
              </div>
              <div className="option1" data-aos="fade-left" id="sec2">
                <h1>Standard Price</h1>
                <div className="bullet-points">
                  <p className="feature">Everything in Package 1</p>
                  <p className="feature">Advanced Freelancing Techniques</p>
                  <p className="feature">Exclusive Bonus Content</p>
                  <p className="feature">Individualized FeedBack</p>
                  <p className="feature">Priority Support</p>
                  <p className="feature">Invest In Your Future</p>
                </div>
                <div className="price-section">
                  <p className="price">300€</p>
                  <p className="txt">one time payment</p>
                </div>
              </div>
            </div>
            <p className="slug"data-aos="fade-up">
              Which needs to be paid before start of the training! So, don't
              waste time and look further,
              <br /> register and grasp the opportunity and change your life.
            </p>
            <button onClick={handleClick} data-aos="fade-up">Join now</button>
          </div>
        </div>
      </div>
    </div>

  );
}



export default Course;
import "./section4.scss"
import React from "react";
import freelanceribw from "../../../assets/freelanceribw.png";
import freelanceri from "../../../assets/freelanceri.png"
import { useState,useEffect } from "react";

const Section4 = () => {

   const handleClick = () => {
    window.location.href = '/contact-us';
    console.log("Clicked");
  };


 const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
      <>
        {windowWidth < 768 ? (
          <>
            <div className="section-4-mobile">
              <div className="wrapper-mobile">
                <div className="title">
                  <h4>
                    A big business
                    <br />
                    starts small.
                  </h4>
                </div>
                <div className="subtitle">
                  At our digital agency, we are proud to serve an elite roster
                  of top clients, helping them achieve their online goals
                  through our innovative strategies and cutting-edge solutions.
                </div>
              </div>
              <div className="row partners">
                {/* <div className="col partner">
                  <img src={freelanceribw} alt="" height={30} />
                </div> */}
                <div className="col start-project" onClick={handleClick}>Start your project</div>
                <div className="col partner">
                  <img src={freelanceri} alt="" height={30} />
                </div>

              </div>
            </div>
          </>
        ) : (
          <>
            <div className="section-4 container">
              <div className="wrapper">
                <div className="title">
                  <h4>
                    A big business
                    <br />
                    starts small.
                  </h4>
                </div>
                <div className="subtitle">
                  At our digital agency, we are proud to serve an elite roster
                  of top clients, helping them achieve their online goals
                  through our innovative strategies and cutting-edge solutions.
                </div>
              </div>
              <div className="partners">
                <div className="partner">
                  <img src={freelanceribw} alt="" height={30} />
                </div>
                <div className="partner">
                  <img src={freelanceribw} alt="" height={30} />
                </div>
                <div className="start-project" onClick={handleClick}>Start your project</div>
                <div className="partner">
                  <img src={freelanceri} alt="" height={30} />
                </div>
                <div className="partner">
                  <img src={freelanceribw} alt="" height={30} />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
}


export default Section4;
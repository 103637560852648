import React from "react";
import "./section6.scss"
import Sec6Img from "../../../assets/images/sec6img.png"
const Section6 = () => {
       const handleClick = () => {
         window.location.href = "/contact-us";
         console.log("Clicked");
       };
    return (
        <>
            <div className="container">
                <div className="rreshti">
                    <div className="firstPart">
                        <div className="col">
                            <h1 className="sec6h1">Attend the Freelancing Course</h1>
                        </div>
                        <div className="col">
                            <p className="firstpart-col1-p">
                            At our digital agency, we are proud to serve an elite roster of top clients, helping them achieve their online goals through our innovative strategies and cutting-edge solutions.
                            </p>
                        </div>
                        <div className="col">
                            <button className="joinNow" onClick={handleClick}>
                                Join Now
                            </button>
                        </div>
                    </div>
                    <div className="imagepart">   
                        <img src={Sec6Img} className="sec6image" alt="sec6img" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Section6
import "./section5.scss"

const Section5 = ()=>{
    return (
      <div className="section5">
        <div className="content">
          <div className="custom-satsf cnt">
            <h4 className="nr">100%</h4>
            <h6 className="text">Customers Satifsaction</h6>
          </div>
          <div className="barrier"></div>
          <div className="serv-prov cnt">
            <h4 className="nr">146</h4>
            <h6 className="text">Services Provided</h6>
          </div>
          <div className="barrier"></div>
          <div className="creative-emp cnt">
            <h4 className="nr">7</h4>
            <h6 className="text">Creative Employees</h6>
          </div>
          <div className="barrier"></div>
          <div className="hours-of-work cnt">
            <h4 className="nr">5382</h4>
            <h6 className="text">Hours of work</h6>
          </div>
        </div>
      </div>
    );
}

export default Section5;
import React from "react";
import "./header.scss";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isContactUsPage = location.pathname === "/contact-us";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  let navItems = [
    { label: "Home", path: "/" },
    { label: "Course", path: "/course" },
    { label: "Contact", path: "/contact-us" },
  ];
  if (windowWidth < 768) {
    navItems = navItems.filter(
      (item) =>
        item.label !== "Course" &&
        item.label !== "Contact" &&
        item.label !== "Home"
    );
  }

  const handleClick = () => {
    navigate("/contact-us");
  };
   
  const handleIconClick = () =>{
    navigate("/")
  }
  return (
    <>
      <header class="header">
        <div className="container">

        <img src={Logo} alt="logo" className="logo" onClick={handleIconClick}/>
        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
        <ul class="menu">
          <li>
            <a class="nav-link" href={'/'}>
              Home
            </a>
          </li>
          <li>
            <a class="nav-link" href={'/course'}>
              Course
            </a>
          </li>
          <li>
            <a className="nav-link" href={'/contact-us'}>
              Contact
            </a>
          </li>
          {/* {windowWidth > 768 ?
            <>
              {!isContactUsPage ? (
                <li>
                  <a className="letsTalkBtn" href="/contact-us">
                    Lets Talk
                  </a>
                </li>
              )
                :
                <div className="">
                </div>
              }
            </>
            :
            ""
          } */}
        </ul>
        </div>
      </header>

      {/* <nav class="navbar navbar-expand-lg">
        <div className="container">
          <img src={Logo} alt="logo" className="logo" href="/" />
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
            <div className="d-flex justify-content-center">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href={'/'}>
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href={'/course'}>
                    Course
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href={'/contact-us'}>
                    Contact
                  </a>
                </li>
                {windowWidth < 768 ?
                  <li className="nav-item mt-3">
                    {!isContactUsPage && (
                      <a className="letsTalkBtn" href="/contact-us">
                        Lets Talk
                      </a>
                    )}
                  </li>
                  :
                  ""
                }
              </ul>
            </div>
          </div>
          {windowWidth > 768 ?
            <>
              {!isContactUsPage ? (
                <a className="letsTalkBtn" href="/contact-us">
                  Lets Talk
                </a>
              )
              :
              <div className="">
              </div>
            }
            </>
            :
         ""
          }
        </div>
      </nav> */}
    </>
  );
};
export default Header;

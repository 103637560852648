import "./contact.scss";
import Select from "react-dropdown-select";
import { useState, useEffect } from "react";
import axios from "axios";
import Aos from "aos";

const options = [
  {
    value: "",
    label: "What do you need?",
  },
  {
    value: 1,
    label: "Consulting",
  },
  {
    value: 2,
    label: "Freelance Course",
  },
];

const ContactUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);

    };
    Aos.init();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [interested_in, setinterested_in] = useState("");
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const interested_in_number = parseInt(interested_in);

    const payload = {
      firstName,
      lastName,
      email,
      description,
      interested_in: {
        value: interested_in_number,
        name: options.find((option) => option.value === interested_in_number)
          ?.label,
      },
    };

    axios
      .post(process.env.REACT_APP_API+"/contact", payload)
      .then((response) => {
        console.log(response.data);
        setFirstName("");
        setLastName("");
        setEmail("");
        setDescription("");
        setinterested_in("");
        setSuccessMessageVisible(true);
        setTimeout(() => {
          setSuccessMessageVisible(false);
        }, 10000);
      })
      .catch((error) => {
        console.error("Error posting data: ", error);
        console.log(payload);
      });
  };

  return (
    <div className="contact-us">
      {windowWidth < 768 ? (
        <>
          <div className="content">
            <div className="texts-mobile"  data-aos="fade-right">
              <h2>Contact Us</h2>
              <p className="tmp">
                Thank you for your interest in reaching out to us. Please fill
                out the form below, and we'll get back to you as soon as
                possible. Or reach us by email:
              </p>
              <p className="tmp">
                Feel free to get in touch with us via email:
              </p>
              <p className="tmp-email">info@arra-click.com</p>
            </div>
            <div className="fields-mobile">
              <form method="post" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="First name"
                  className="form_input names"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last name"
                  required
                  className="form_input names"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="mail"
                  placeholder="Email"
                  className="mail_input"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                  id="multilineInput"
                  name="multilineInput"
                  placeholder="Description"
                  required
                  rows="5"
                  cols="50"
                  className="form_input"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <div className="form-footer">
                  <div className="dropdown">
                    <select
                      value={interested_in}
                      onChange={(e) => setinterested_in(e.target.value)}
                      name="interested_in"
                      className="select-style"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button className="send-btn" onClick={handleSubmit}>
                    Send
                  </button>
                </div>
                <div className="message">
                  {successMessageVisible && <p>Form submitted successfully!</p>}
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="content">
            <div className="row">
              <div className="col-sm-6 texts">
                <div className="texts-content"data-aos="fade-right">
                  <div className="description" >
                    <h1 className="dsc-contactus">Contact Us</h1>
                    <p>
                      Thank you for your interest in reaching out to us. Please
                      fill out the form below, and we'll get back to you as soon
                      as possible. Or reach us by email:
                    </p>
                  </div>
                  <div className="contact-info">
                    <h6>Feel free to get in touch with us via email:</h6>
                    <div className="email-phone">
                      <h6>info@arra-click.com</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 fields">
                <form onSubmit={handleSubmit} data-aos="fade-left">
                  <div className="names-row">
                    <input
                      type="text"
                      placeholder="First name"
                      className="form_input names"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Last name"
                      className="form_input name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <input
                    type="mail"
                    placeholder="Email"
                    className="mail_input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <textarea
                    id="multilineInput"
                    name="multilineInput"
                    placeholder="Description"
                    rows="5"
                    cols="50"
                    className="form_input"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <div className="form-footer">
                    <div className="dropdown">
                      <select
                        value={interested_in}
                        onChange={(e) => setinterested_in(e.target.value)}
                        name="interested_in"
                        className="select-style"
                        required
                      >
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button className="send-btn" type="submit">
                      Send
                    </button>
                  </div>
                  <div className="message">
                    {successMessageVisible && (
                      <p>Form submitted successfully!</p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactUs;

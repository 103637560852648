import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router , Routes , Route} from 'react-router-dom';
import{useEffect,useState} from "react";
import Home from './pages/Home/home'
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Course from './pages/course/course';
import ContactUs from './pages/contactus/contact';


function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/course" element={<Course />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;

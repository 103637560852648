import React from "react";
import "./footer.scss";
import WaterMark from "../../assets/freelamceriwhite.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const isContactUsPage = location.pathname === "/contact-us";

  return (
    <>
      {!isContactUsPage && (
        <div className="footer">
          <div className="container ">
            <div className="row footerItems">
              <div className="col cr">
                @2022 Arra Click. All Rights Reserved
              </div>
              <div className="col d-flex justify-content-center frillenser">
                <p>Powered by: </p>
                <img src={WaterMark} alt="watermark" className="freelanceri"/>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col privacy">Pricavy Policy</div>
                  <div className="col tc">Terms & Conditions</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Footer;

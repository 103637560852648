import React, { useEffect } from "react";
import './home.scss'
import Section1 from "../../components/homeComponents/section1/section1";
import Section2 from "../../components/homeComponents/section2/section2";
import Section3 from "../../components/homeComponents/section3/section3";
import Section4 from "../../components/homeComponents/section4/section4";
import Section5 from "../../components/homeComponents/section5/section5";
import Section6 from "../../components/homeComponents/section6/section6";
import Aos from "aos";


const Home = () => {
  useEffect(()=> {
    Aos.init();
  },[])
    return (
      <>
      <div data-aos="fade-down">
        <Section1 />
      </div>
      <div data-aos="fade-down">
        <Section2 />
        </div>
      <div data-aos="fade-left">
        <Section3 />
        </div>
      <div data-aos="fade-up">
        <Section4 />
        </div>
      <div data-aos="fade-right">
        <Section5 />
        </div>
      <div data-aos="fade-left">
        <Section6/>
        </div>
      </>
    );
}
export default Home
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FightClub from "../../../assets/images/fightClub.png";
import Shopez from "../../../assets/images/shopez.png";
import Smart from "../../../assets/images/Smart.png";
import "./section2.scss";
import { useState, useEffect } from "react";

const Section2 = () => {
  const categories = [
    {
      img: FightClub,
      title: "Crypto Fight Club",
      desc: "Web Development",
    },
    {
      img: Shopez,
      title: "Shopez",
      desc: "Brand Identity",
    },
    {
      img: Smart,
      title: "Smart",
      desc: "Web Development",
    },
  ];
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 2.1, //PC
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    swipe: true,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // max width
        settings: {
          slidesToShow: 1, // MOBILE
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth < 768 ? (
        <>
          <div className="section2-mobile">
            <div className="s2-wrap-mobile">
              <div className="category-slider-mobile">
                <div class="grid-container">
                  <div class="grid-item slider-box">
                    <img
                      src={FightClub}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Fight Club</h5>
                    <p style={{ fontSize: "10px" }}>Web Development</p>
                  </div>
                  <div class="grid-item slider-box">
                    <img
                      src={Shopez}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Shopez</h5>
                    <p style={{ fontSize: "10px" }}>Brand Identity</p>
                  </div>
                  <div class="grid-item slider-box">
                    <img
                      src={Smart}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Smart</h5>
                    <p style={{ fontSize: "10px" }}>Web Developement</p>
                  </div>
                </div>
              </div>
              <div className="cardalignment-mobile">
                <div className="sec2card-mobile container">
                  <p className="sec2Paragraf-mobile">
                    Make changes in something{" "}
                    <span style={{ color: "#3EBEA3" }}>established</span>,
                    especially by introducing new methods, ideas, or{" "}
                    <span style={{ color: "#2b57ae" }}>products.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="section2">
            <div className="s2-wrap">
              <div className="category-slider container">
                <div class="grid-container">
                  <div class="grid-item slider-box">
                    <img
                      src={FightClub}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Fight Club</h5>
                    <p style={{ fontSize: "10px" }}>Web Development</p>
                  </div>
                  <div class="grid-item slider-box">
                    <img
                      src={Shopez}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Shopez</h5>
                    <p style={{ fontSize: "10px" }}>Brand Identity</p>
                  </div>
                  <div class="grid-item slider-box">
                    <img
                      src={Smart}
                      alt=""
                      className="grid-item-img"
                      width={400}
                      height={300}
                      style={{ borderRadius: "12px" }}
                    />
                    <h5>Smart</h5>
                    <p style={{ fontSize: "10px" }}>Web Developement</p>
                  </div>
                </div>
              </div>
              <div className="cardalignment">
                <div className="sec2card container">
                  <p className="sec2Paragraf">
                    Make changes in something{" "}
                    <span style={{ color: "#3EBEA3" }}>established</span>,
                    especially by introducing new methods, ideas, or{" "}
                    <span style={{ color: "#2b57ae" }}>products.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Section2;

import React from "react";
import './section1.scss'
import Sec1Mouse from '../../../assets/images/sec1Mouse.png'
import { useState, useEffect } from "react";
// import Typewriter from 'typewriter-effect/dist/core';
import Typewriter from 'typewriter-effect';
import axios from "axios";

new Typewriter('#typewriter', {
    strings: ['Hello', 'World'],
    autoStart: true,
});



const Section1 = () => {
     const [windowWidth, setWindowWidth] = useState(window.innerWidth);
     useEffect(() => {
       const handleResize = () => {
         setWindowWidth(window.innerWidth);
       };

       window.addEventListener("resize", handleResize);

       return () => {
         window.removeEventListener("resize", handleResize);
       };
     }, []);


    const [email,setEmail]=useState(""); 

     const handleSubmit = (e)=>{
      e.preventDefault();

      const payload ={
        email
      };


      axios.post(process.env.REACT_APP_API+"/email",payload)
      .then((res)=>{
        console.log(res.data);
        setEmail("");
      }).catch((error)=>{
        console.log("Error posting data:",error);
        console.log(payload);
      })

     }

    return (
      <>
        {windowWidth < 768 ? (
          <>
            <div className="section1-mobile">
              <div className="container">
                <div className="headerCard-mobile">
                  <div className="sec1Text-mobile">
                    <h1 className="s1t1h1">
                      Your Digital&nbsp;
                      <Typewriter
                        onInit={(typewriter) => {
                          typewriter
                            .typeString(" Friend")
                            .pauseFor(2000)
                            .deleteAll()
                            .typeString(" Solution")
                            .pauseFor(2000)
                            .deleteAll()
                            .typeString(" Agency")
                            .start();
                        }}
                      />
                    </h1>
                    <p className="s1t1p1">
                      Welcome to our digital freelancing agency, where we bring
                      your digital dreams to life. We're your dedicated team of
                      experts, here to empower your business's online success.
                    </p>
                    <div className="inpArea-mobile">
                      <div className="inpBtn-mobile">
                        <input
                          className="inputEmail-mobile"
                          placeholder="Email"
                          type="email"
                          name=""
                          id=""
                        />
                        <button className="sendBtn-mobile">send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="section1">
              <div className="container">
                <div className="headerCard">
                  <div className="sec1Text">
                    <div className="">
                      <div className="d-flex justify-content-center">
                        <h1 className="h1Animation">
                          Your Digital{" "}
                          <Typewriter
                            onInit={(typewriter) => {
                              typewriter
                                .typeString("Friend")
                                .pauseFor(2000)
                                .deleteAll()
                                .typeString("Solution")
                                .pauseFor(2000)
                                .deleteAll()
                                .typeString("Agency")
                                .start();
                            }}
                          />
                        </h1>
                      </div>
                      <div className="d-flex justify-content-center">
                        <span className="mt-3 mb-3">
                          Welcome to our digital freelancing agency, where we
                          bring your digital dreams to life. We're your
                          dedicated team of experts, here to empower your
                          business's online success.
                        </span>
                      </div>
                      <div className="inpArea">
                        <div className="inpBtn">
                          <input
                            className="inputEmail"
                            placeholder="Email"
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                          <button className="sendBtn" onClick={handleSubmit}>Subscribe</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <img src={Sec1Mouse} alt="sec1Mouse" className="sec1Mouse" />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
}
export default Section1;
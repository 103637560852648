import "./section3.scss";
import graphicdesign from "../../../assets/images/design.png";
import webdev from "../../../assets/images/webdev.png";
import datascience from "../../../assets/images/data-science.png";
import virtualassist from "../../../assets/images/virtual.png";
import data from "../../../assets/images/data-analysis.png";
import hr from "../../../assets/images/hr.png";
import digitalmarketing from "../../../assets/images/digitalmarketing.png";
import { useState, useEffect } from "react";

const Section3 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="mt-2 mb-5 services">
      <div className="container">
        <div className="row row1">
          <div className="col-sm-4 services-txt">
            <h3>Services</h3>
            <p>
              Welcome al freelancing agency, where we bring you digital dreams
              to life.
            </p>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={graphicdesign} alt="" width={37} />
                <h5>Graphic Design</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Creating captivating visuals and innovative designs to elevate
                  your brand identity and communication.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={webdev} alt="" height={37} />
                <h5>Web Development</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  WBuilding dynamic websites with clean code and intuitive
                  navigation for a standout online presence.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={graphicdesign} alt="" width={37} />
                <h5>Ui/Ux</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Crafting seamless user experiences and visually striking
                  interfaces to make your digital presence shine.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 row2">
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={datascience} alt="" width={37} />
                <h5>Data Science</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Leveraging data to extract valuable insights and drive
                  informed decision-making for your business.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={virtualassist} alt="" width={37} />
                <h5>Virtual Assistance</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Providing reliable and efficient support remotely,
                  streamlining tasks and enhancing productivity.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={data} alt="" width={37} />
                <h5>Data Analysis</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Analyzing data sets to uncover patterns and trends, enabling
                  strategic decision-making and optimization.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={hr} alt="" width={37} />
                <h5>Human Resources</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Offering expert HR solutions tailored to your organization's
                  needs, from recruitment to employee relations.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src={digitalmarketing} alt="" width={37} />
                <h5>Digital Marketing</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Strategizing and executing targeted campaigns across digital
                  channels to boost brand visibility and engagement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
